import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import classNames from 'clsx';
import { Link, withAssetPrefix } from 'gatsby';
import React, { Component } from 'react';
import { compose } from 'recompose';

import Layout from '@/components/layout';
import SEO from '@/components/seo';

const noImage = '/assets/images/noImage.jpg';

type Props = {
  classes: {
    root: string;
    breadCrumb: string;
    breadCrumbSplit: string;
    componentWrapper: string;
    itmes: string;
    texts: string;
    background: string;
    title: string;
    content: string;
    meta: string;
  };
  pageContext: {
    title: string;
    date: string;
    content: string;
    featured_media?: {
      source_url: string;
    };
    acf?: {
      author?: string;
    };
  };
};

type State = {
  checked: boolean;
};

class OurStoryPage extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      checked: false,
    };
  }

  _formatDate(wordpressDate: string) {
    const dateObj = new Date(wordpressDate);
    return (
      dateObj.getFullYear() +
      '.' +
      (dateObj.getMonth() + 1) +
      '.' +
      dateObj.getDate()
    );
  }

  render() {
    const { classes } = this.props;
    const story = this.props.pageContext;

    return (
      <Layout>
        <SEO title={story.title} />
        <div className={classes.root}>
          <div className={classes.breadCrumb}>
            <Link to={'/'}>TOP</Link>
            <span className={classes.breadCrumbSplit}> {'>'} </span>
            <Link to={'/stories'}>OUR STORIES一覧</Link>
            <span className={classes.breadCrumbSplit}> {'>'} </span>
            {story.title}
          </div>
          <div
            className={classes.background}
            style={{
              background: `no-repeat center/cover url(
                ${
                  story.featured_media && story.featured_media.source_url
                    ? story.featured_media.source_url
                    : withAssetPrefix(noImage)
                }
              )`,
            }}
          />
          <div className={classNames(classes.componentWrapper, 'article')}>
            <div className={classes.itmes}>
              <div className={classes.texts}>
                <div className={classes.meta}>
                  <div>{this._formatDate(story.date)}</div>
                  {story.acf && story.acf.author ? (
                    <div>{story.acf.author}</div>
                  ) : null}
                  <div className={classes.title}> {story.title}</div>
                </div>
                <div
                  className={classes.content}
                  dangerouslySetInnerHTML={{ __html: story.content }}
                />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: '40px 80px',
      [theme.breakpoints.down('sm')]: {
        padding: '40px 80px',
      },
      [theme.breakpoints.down('xs')]: {
        padding: '10px 20px 20px 20px',
      },
    },
    breadCrumb: {
      fontFamily:
        '"Noto Sans JP", -apple-system, BlinkMacSystemFont, "Helvetica Neue", YuGothic, "ヒラギノ角ゴ ProN W3", Hiragino Kaku Gothic ProN, Arial, "メイリオ", Meiryo, sans-serif',
      fontSize: 18,
      marginBottom: 40,
      marginLeft: 20,
      [theme.breakpoints.down('sm')]: {
        fontSize: 15,
        marginBottom: 30,
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: 10,
        marginBottom: 10,
      },
    },
    breadCrumbSplit: {
      fontFamily: 'Advent Pro',
      fontWeight: 'bold',
      marginLeft: 10,
      marginRight: 10,
      color: '#999',
    },
    componentWrapper: {
      margin: '0 auto',
      maxWidth: 980,
      padding: '40px 80px',
      backgroundColor: '#FFF',
      [theme.breakpoints.down('sm')]: {
        padding: '20px 40px',
      },
      [theme.breakpoints.down('xs')]: {
        padding: '10px 20px',
      },
    },
    itmes: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-around',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
    },
    texts: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      marginLeft: 20,
      [theme.breakpoints.down('sm')]: {
        marginLeft: 0,
      },
      [theme.breakpoints.down('xs')]: {
        marginLeft: 0,
      },
    },
    background: {
      height: 600,
      maxWidth: 980,
      margin: 'auto',
      [theme.breakpoints.down('md')]: {
        height: 620,
      },
      [theme.breakpoints.down('sm')]: {
        height: 440,
      },
      [theme.breakpoints.down('xs')]: {
        height: 240,
      },
    },
    title: {
      marginTop: 30,
      marginBottom: 30,
      fontFamily:
        '"Sawarabi Mincho", -apple-system, BlinkMacSystemFont, "Helvetica Neue", YuGothic, "ヒラギノ角ゴ ProN W3", Hiragino Kaku Gothic ProN, Arial, "メイリオ", Meiryo, sans-serif',
      fontStyle: 'normal',
      fontStretch: 'normal',
      fontWeight: 'bold',
      fontSize: 30,
      lineHeight: 1.2,
      borderLeft: '4px solid #1e365c',
      paddingLeft: 10,
      [theme.breakpoints.down('sm')]: {
        marginBottom: 30,
      },
      [theme.breakpoints.down('xs')]: {
        marginBottom: 20,
      },
    },
    content: {
      fontFamily:
        '"Noto Sans JP", -apple-system, BlinkMacSystemFont, "Helvetica Neue", YuGothic, "ヒラギノ角ゴ ProN W3", Hiragino Kaku Gothic ProN, Arial, "メイリオ", Meiryo, sans-serif',
      fontStyle: 'normal',
      fontStretch: 'normal',
      fontSize: 18,
    },
    meta: {
      fontFamily:
        '"Sawarabi Mincho", -apple-system, BlinkMacSystemFont, "Helvetica Neue", YuGothic, "ヒラギノ角ゴ ProN W3", Hiragino Kaku Gothic ProN, Arial, "メイリオ", Meiryo, sans-serif',
    },
  });

export default compose(withStyles(styles))(OurStoryPage);
